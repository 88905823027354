import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActions,
  Box,
} from "@mui/material";

interface WootBlogPostCardProps {
  title: string;
  excerpt: string;
  image: string;
  onReadMore: () => void;
}

const WootBlogPostCard = ({
  title,
  excerpt,
  image,
  onReadMore,
}: WootBlogPostCardProps) => {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "flex-start",
        textAlign: "left",
        borderRadius: "10px",
      }}
      variant="outlined"
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          width: { xs: 70, md: 140 },
          height: { xs: 70, md: 140 },
          objectFit: "cover",
          transition: "width 0.3s ease, height 0.3s ease",
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {excerpt}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={onReadMore} disabled>
            Read More
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};

export default WootBlogPostCard;
