import React from "react";
import { Box, Container, Typography } from "@mui/material";
import WootLogo from "../components/WootLogo";
import WootButton from "../components/WootButton";
import { HelpOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <Container
      sx={{
        padding: "20px",
        textAlign: "center",
        marginTop: "50px",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "200px",
          margin: "0 auto",
        }}
      >
        <WootLogo />
      </Box>
      <HelpOutline sx={{ fontSize: "50px" }} />
      <Typography variant="h3" component="div" sx={{ marginTop: "20px" }}>
        404 - Not Found
      </Typography>
      <Typography variant="h6" sx={{ marginTop: "20px" }}>
        ...how did you get here?
      </Typography>
      <Link to="/home">
        <WootButton sx={{ mt: 2 }} wootStyles={{ variant: "secondary" }}>
          Return Home
        </WootButton>
      </Link>
    </Container>
  );
}

export default NotFoundPage;
