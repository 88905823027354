import React, { useState } from "react";
import {
  Box,
  TextField,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";

interface WootSignupFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootSignupForm({ emailState, menuState }: WootSignupFormProps) {
  const [email, setEmail] = emailState;
  const [password, setPassword] = useState("");
  const [above13, setAbove13] = useState(false);
  const [error, setError] = useState("");
  const [, setMenuState] = menuState;

  const handleSignup = async () => {
    setError("");

    try {
      const response = await fetch(`${appConfig.warden_url}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          above_13: above13,
          email: email,
          password: password,
        }),
      });

      if (!response.ok) {
        // Extract the response text for error message
        const errorText = await response.text();
        throw new Error(errorText || "Failed to signup");
      }

      // Signup successful, verify with a code now.
      setMenuState(LoginMenu.VerifyEmail);
    } catch (err) {
      if (err instanceof Error) {
        // If the error is of type Error, handle it safely
        setError(err.message || "An unknown error occurred");
      } else {
        // Fallback for other types of errors
        setError("An unexpected error occurred");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <WootForm onSubmit={handleSignup} sx={{ mt: 1 }} submitText="Sign up">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="abc123"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <FormControlLabel
          label="I am above the age of 13"
          control={
            <Checkbox
              checked={above13}
              onChange={(e) => setAbove13(e.target.checked)}
              color="secondary"
            />
          }
        />
      </WootForm>
      <Link
        to="#"
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          setMenuState(LoginMenu.Signin);
        }}
      >
        {"← Back to Login"}
      </Link>
    </Box>
  );
}

export default WootSignupForm;
