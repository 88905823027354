import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Box,
} from "@mui/material";
import WootButton from "../WootButton";
import { WootOffer } from "./types";

interface WootOfferCardProps {
  offer: WootOffer;
  onBuyClick: () => void;
}

const WootOfferCard = ({ offer, onBuyClick }: WootOfferCardProps) => {
  const price = (offer.default_price.unit_amount / 100).toFixed(2);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        textAlign: "left",
        minHeight: "150px",
        borderRadius: "10px",
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {offer.images.length > 0 && (
          <CardMedia
            component="img"
            image={offer.images[0]}
            alt={offer.name}
            sx={{
              width: { xs: 100, md: 150 },
              height: { xs: 100, md: 150 },
              marginLeft: 2,
              marginY: "auto",
              objectFit: "contain",
              transition: "width 0.3s ease, height 0.3s ease",
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
          }}
        >
          <CardContent sx={{ height: "100%" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: { xs: 30, md: 40 },
                transition: "font-size 0.3s ease",
              }}
            >
              {offer.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: { xs: 16, md: 24 },
                transition: "font-size 0.3s ease",
              }}
            >
              {offer.description}
            </Typography>
          </CardContent>
        </Box>
      </Box>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "stretch",
          padding: 2,
          marginLeft: "auto",
          height: "100%",
          minWidth: { xs: "100%", sm: "175px" }, // Adjust width for xs
          width: { xs: "100%", sm: "20%" }, // Adjust width for xs
        }}
      >
        <WootButton
          wootStyles={{ variant: "secondary" }}
          onClick={onBuyClick}
          sx={{
            width: "100%",
            height: { xs: "100%", sm: 115 },
            borderRadius: "10px",
            textAlign: "center",
            fontSize: { xs: "1em", md: "1.2em" },
            transition: "width 0.3s ease, font-size 0.3s ease",
          }}
        >
          Purchase
          <br />
          {(offer.default_price.currency === "usd" && `$${price}`) ||
            `${price} ${offer.default_price.currency}`}
        </WootButton>
      </CardActions>
    </Card>
  );
};

export default WootOfferCard;
