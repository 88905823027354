import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AppBarRightContent from "./AppBarRightContent";
import AppBarNavContent from "./AppBarNavContent";

function ResponsiveAppBar() {
  return (
    <AppBar
      position="static"
      sx={{
        borderRadius: { xs: 0, lg: "20px" }, // No border radius on small screens, 20px on large+
        mb: { xs: 0, lg: 2 }, // No margin on small screens, 2 units margin on large+
        mt: { xs: 0, lg: 2 }, // Same for top margin
      }}
      color="secondary"
    >
      <Toolbar>
        <AppBarNavContent />
        <AppBarRightContent />
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
