import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0948B6", // Blue
    },
    secondary: {
      main: "#ffbd2e", // Gold
      light: "#ffd557",
      dark: "#da9500",
    },
    error: {
      main: "#FF241D", // Red
    },
    success: {
      main: "#69ff47", // Green
    },
    background: {
      default: "#0948B6", // Blue
      paper: "#F7FCFD",
    },
    text: {
      primary: "#18181B", // Black
      secondary: "#363A3E", // Gray
    },
    common: {
      white: "#F7FCFD",
      black: "#18181B", // Black
    },
  },
  typography: {
    fontFamily: "Fredoka, Comic Sans MS, Arial, sans-serif", // Playful font like Club Penguin
  },
});

export default theme;
