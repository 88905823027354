import React from "react";
import { CircularProgress } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

interface WootLoadingLinkProps extends LinkProps {
  loading: boolean; // Loading state passed from the parent
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  children: React.ReactNode;
}

function WootLoadingLink({
  loading,
  onClick,
  children,
  ...props
}: WootLoadingLinkProps) {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!loading && onClick) {
      onClick(event);
    }
  };

  return (
    <Link
      style={{
        display: "inline-flex",
        alignItems: "center",
        textDecoration: loading ? "none" : "underline", // Optionally change the text decoration when loading
        opacity: loading ? 0.6 : 1, // Optionally adjust opacity when loading
        cursor: loading ? "not-allowed" : "pointer",
      }}
      onClick={handleClick}
      {...props}
    >
      {loading && (
        <CircularProgress
          size={20}
          color="primary"
          style={{
            marginRight: 8, // Add some space between the spinner and text
          }}
        />
      )}
      {children}
    </Link>
  );
}

export default WootLoadingLink;
