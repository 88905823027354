import { Button, ButtonProps } from "@mui/material";

export interface WootButtonStyles {
  variant?: "primary" | "secondary" | "destructive";
}

export interface WootButtonProps extends ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  wootStyles?: WootButtonStyles;
}

const buttonStylePrimary = {
  borderRadius: "50px",
  fontWeight: "bold",
  backgroundColor: "primary.main",
  color: "common.white",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "primary.light",
  },
};

const buttonStyleSecondary = {
  borderRadius: "50px",
  fontWeight: "bold",
  backgroundColor: "secondary.main",
  color: "common.black",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "secondary.light",
  },
};

const buttonStyleDestructive = {
  borderRadius: "50px",
  fontWeight: "bold",
  backgroundColor: "error.main",
  color: "common.white",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "error.light",
  },
};

const WootButton = ({
  onClick,
  children,
  wootStyles,
  sx, // include sx for merging custom styles
  ...props
}: WootButtonProps) => {
  let buttonStyle;

  // Use a switch to select the button style
  switch (wootStyles?.variant) {
    case "secondary":
      buttonStyle = buttonStyleSecondary;
      break;
    case "destructive":
      buttonStyle = buttonStyleDestructive;
      break;
    case "primary":
    default:
      buttonStyle = buttonStylePrimary;
      break;
  }

  return (
    <Button
      onClick={onClick}
      sx={{
        ...buttonStyle,
        ...sx, // Merge with any additional styles passed through sx prop
      }}
      {...props} // Spread the rest of the props (like onClick, disabled, etc.)
    >
      {children}
    </Button>
  );
};

export default WootButton;
