import React from "react";
import { CircularProgress, ButtonProps } from "@mui/material";
import WootButton, { WootButtonProps } from "../WootButton";

interface WootFormSubmitButtonProps extends WootButtonProps {
  sx?: ButtonProps["sx"];
  loading: boolean; // Loading state passed from the parent
  children: React.ReactNode;
}

function WootFormSubmitButton({
  sx,
  loading,
  children,
  ...props
}: WootFormSubmitButtonProps) {
  return (
    <WootButton
      type="submit"
      sx={{
        backgroundColor: loading ? "secondary.dark" : "secondary.main",
        color: loading ? "common.black" : "common.black",
        position: "relative",
        minWidth: "120px",
        minHeight: "44.5px",
        "&:hover": {
          backgroundColor: loading ? "secondary.dark" : "secondary.light",
        },
        ...sx,
      }}
      disabled={loading}
      {...props}
    >
      {loading ? (
        <CircularProgress
          size={24}
          color="primary"
          sx={{
            position: "absolute",
          }}
        />
      ) : (
        children
      )}
    </WootButton>
  );
}

export default WootFormSubmitButton;
