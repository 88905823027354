import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { appConfig } from "../config";

// Load Stripe outside of the component to avoid recreating on every render
const stripePromise = loadStripe(appConfig.stripe_public_key);

interface StripeEmbeddedCheckoutProps {
  product_id: string;
}

const Checkout = ({ product_id }: StripeEmbeddedCheckoutProps) => {
  const { getAccessToken } = useAuth();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showSignInDialog, setShowSignInDialog] = useState(false);

  // Fetch the client secret
  const fetchClientSecret = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        // Show the sign-in dialog if accessToken is missing
        setShowSignInDialog(true);
        return;
      }

      const response = await fetch(
        `${appConfig.aura_url}/stripe/create-checkout-session/${product_id}?redirect=${appConfig.base_url}/shop`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch client secret");

      const body = await response.json();
      setClientSecret(body["client_secret"]);
    } catch (error) {
      console.error("Error initializing checkout session:", error);
    }
  }, [getAccessToken, product_id]);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  const handleDialogClose = () => {
    setShowSignInDialog(false);
  };

  if (clientSecret) {
    const options = {
      clientSecret,
    };

    return (
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    );
  }

  return (
    <Box>
      <CircularProgress
        size={100}
        sx={{ marginTop: "20px" }}
        color="secondary"
      />
      {/* Sign-In Dialog */}
      <Dialog open={showSignInDialog} onClose={handleDialogClose}>
        <DialogTitle>Sign-In Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You must be signed in to access the checkout process. Please sign in
            to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
          <Button
            href={`${appConfig.base_url}/login?redirect=/shop`}
            color="primary"
            variant="contained"
          >
            Go to Sign In
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Checkout;
