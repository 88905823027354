import React, { useState } from "react";
import { Box, TextField, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";

interface WootForgotPasswordFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootForgotPasswordForm({
  emailState,
  menuState,
}: WootForgotPasswordFormProps) {
  const [email, setEmail] = emailState;
  const [error, setError] = useState("");
  const [, setMenuState] = menuState;

  const handleForgotPassword = async () => {
    setError("");

    try {
      const response = await fetch(`${appConfig.warden_url}/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (!response.ok) {
        // Extract the response text for error message
        const errorText = await response.text();
        throw new Error(errorText || "Failed to send password reset");
      }

      // Resend successful, moving to password reset
      setMenuState(LoginMenu.ResetPassword);
    } catch (err) {
      if (err instanceof Error) {
        // If the error is of type Error, handle it safely
        setError(err.message || "An unknown error occurred");
      } else {
        // Fallback for other types of errors
        setError("An unexpected error occurred");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <WootForm
        onSubmit={handleForgotPassword}
        sx={{ mt: 1 }}
        submitText="Send password reset email"
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
      </WootForm>
      <Link
        to="#"
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          setMenuState(LoginMenu.Signin);
        }}
      >
        {"← Back to Login"}
      </Link>
    </Box>
  );
}

export default WootForgotPasswordForm;
