import React, { useState } from "react";
import { Box, TextField, Alert, Typography } from "@mui/material";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";
import WootLoadingLink from "../WootLoadingLink";
import WootButton from "../WootButton";

interface WootEmailVerificationFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootEmailVerificationForm({
  emailState,
  menuState,
}: WootEmailVerificationFormProps) {
  const [email, setEmail] = emailState;
  const [, setMenuState] = menuState;
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [verifiedSuccess, setVerifiedSuccess] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);

  const handleVerify = async () => {
    // Reset state
    setError("");
    setVerifiedSuccess(false);
    setResendSuccess(false);

    try {
      // Encode email to ensure it's safe for the URL
      const encodedEmail = encodeURIComponent(email);

      const response = await fetch(
        `${appConfig.warden_url}/verify-email?code=${code}&email=${encodedEmail}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to verify email");
      }

      setVerifiedSuccess(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message || "An unknown error occurred");
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  const handleResendVerification = async () => {
    // Reset state
    setError("");
    setResendSuccess(false);

    // Set loading
    setResendLoading(true);

    try {
      const response = await fetch(`${appConfig.warden_url}/resend-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to verify email");
      }

      // Success, switch to the login menu
      setResendSuccess(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message || "An unknown error occurred");
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {(verifiedSuccess && (
        <Alert severity="success">Email verification successful!</Alert>
      )) ||
        (resendSuccess && <Alert severity="success">Email resent.</Alert>) || (
          <Typography color="common.black" sx={{ textAlign: "center" }}>
            Check your email for a verification code.
          </Typography>
        )}
      {error && <Alert severity="error">{error}</Alert>}
      {!verifiedSuccess && (
        <>
          <WootForm onSubmit={handleVerify} sx={{ mt: 1 }} submitText="Verify">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              placeholder="john@smith.com"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ sx: { color: "text.secondary" } }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              placeholder="123456"
              label="Code"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              InputProps={{ sx: { color: "text.secondary" } }}
            />
          </WootForm>
          <WootLoadingLink
            to="#"
            onClick={handleResendVerification}
            loading={resendLoading}
          >
            Resend verification code
          </WootLoadingLink>
        </>
      )}
      {verifiedSuccess && (
        <WootButton
          sx={{
            mt: 1,
          }}
          onClick={() => {
            setMenuState(LoginMenu.Signin);
          }}
          wootStyles={{
            variant: "secondary",
          }}
        >
          Go to Login
        </WootButton>
      )}
    </Box>
  );
}

export default WootEmailVerificationForm;
