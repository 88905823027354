import React from "react";
import { Grid } from "@mui/material";
import WootOfferCard from "./WootOfferCard";
import { WootOffer } from "./types";

interface WootOfferListProps {
  offers: WootOffer[];
  onBuyClick: (product_id: string) => void;
}

const WootOfferList = ({ offers, onBuyClick }: WootOfferListProps) => {
  return (
    <Grid
      container
      spacing={4}
      direction="column"
      sx={{
        flex: 1, // Make the list grow to fill available height
        overflowY: "auto", // Allow scrolling if content exceeds height
        pb: 1, // Hack because the list clips the bottom shadow
      }}
    >
      {offers.map((offer) => (
        <Grid item key={offer.id} xs={12}>
          <WootOfferCard
            offer={offer}
            onBuyClick={function () {
              onBuyClick(offer.id);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default WootOfferList;
