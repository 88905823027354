import React, { useState } from "react";
import { Container, Box, Card, CardContent, CardHeader } from "@mui/material";
import WootLogo from "../components/WootLogo";
import WootLogin, { LoginMenu } from "../components/WootLogin/WootLogin";
import { Link } from "react-router-dom";

function LoginPage() {
  const menuState = useState(LoginMenu.Signin);
  const [menu] = menuState;

  var title;
  switch (menu) {
    case LoginMenu.VerifyEmail:
      title = "Verify email";
      break;
    case LoginMenu.Signup:
      title = "Sign up";
      break;
    case LoginMenu.ForgotPasswordForm:
      title = "Forgot password?";
      break;
    case LoginMenu.ResetPassword:
      title = "Reset password";
      break;
    case LoginMenu.Signin:
    default:
      title = "Login";
      break;
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        margin: "50px auto",
      }}
    >
      <Card
        sx={{
          borderRadius: "16px", // Rounded corners for the card
          boxShadow: 3, // Optional: Add some shadow to give depth
        }}
      >
        <CardHeader
          title={title}
          titleTypographyProps={{
            align: "center", // Center the title text
            variant: "h6", // Adjust typography as needed
          }}
          sx={{
            backgroundColor: "secondary.main", // Background color for the header
            padding: 1, // Adjust padding
            borderTopLeftRadius: "16px", // Round top-left corner
            borderTopRightRadius: "16px", // Round top-right corner
          }}
        />
        <CardContent>
          <Link
            to="/home"
            style={{
              textDecoration: "none", // Remove underline
            }}
          >
            {"← Go Home"}
          </Link>
          <Box
            sx={{
              margin: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                maxWidth: "100%",
                overflow: "hidden",
                width: "200px",
              }}
            >
              <WootLogo />
            </Box>
            <WootLogin menuState={menuState} />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default LoginPage;
