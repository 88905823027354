import { To } from "react-router-dom";

export interface NavLink {
  label: string;
  to: To;
}

export const PAGES: NavLink[] = [
  {
    label: "News",
    to: "/home",
  },
  { label: "Shop", to: "/shop" },
  { label: "Play", to: "/play" },
];
