import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthContext";
import { appConfig } from "../config";
import { useLocation, useNavigate } from "react-router-dom";

interface AccountContextType {
  account: Account | null;
  error: string | null;
  loading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

export interface Account {
  data: any | null;
  isCreated: boolean;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getAccessToken, setAccessToken } = useAuth(); // Use the promise-based token retrieval
  const [account, setAccount] = useState<Account | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const logout = async () => {
    const fetchSignout = async () => {
      try {
        const response = await fetch(`${appConfig.warden_url}/signout`, {
          method: "GET",
          credentials: "include", // Include credentials (cookies) for logout
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText || "Please try again.");
        }
        setError(null);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message || "An unknown error occurred");
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setAccount(null);
        setAccessToken(null);
      }
    };

    setLoading(true);
    await fetchSignout();
    setLoading(false);
  };

  const login = useCallback(async () => {
    const fetchAccountData = async (token: string) => {
      try {
        const response = await fetch(`${appConfig.aura_url}/account/get`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          // Account exists
          const data = await response.json();
          setAccount({
            data: data,
            isCreated: true,
          });
          setError(null);
        } else if (response.status === 400) {
          setAccount({
            data: null,
            // Account does not exist
            isCreated: false,
          });
          setError(null);
        } else {
          // Unexpected result
          const errorText = await response.text();
          throw new Error(errorText || "Failed to retrieve account");
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message || "An unknown error occurred");
        } else {
          setError("An unexpected error occurred");
        }
        setAccount(null);
      }
    };

    setLoading(true);
    try {
      const token = await getAccessToken(); // Get valid access token
      if (token) {
        await fetchAccountData(token);
      }
    } catch (error) {
      console.error("Failed to log in", error);
      setError("Failed to log in");
    } finally {
      setLoading(false);
    }
  }, [getAccessToken]);

  useEffect(() => {
    const isAccountCreationPage = location.pathname === "/create-account";

    if (!isAccountCreationPage && account?.isCreated === false) {
      const redirectUrl = encodeURIComponent(location.pathname);
      navigate(`/create-account?redirect=${redirectUrl}`);
    }
  }, [account, location.pathname, navigate]);

  return (
    <AccountContext.Provider
      value={{
        account,
        error,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
