import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { WootBlogPost } from "./types";
import WootBlogPostCard from "./WootBlogPostCard";

interface WootBlogPostListProps {
  posts: WootBlogPost[];
  postsPerPage: number;
}

const WootBlogPostList = ({ posts, postsPerPage }: WootBlogPostListProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleReadMore = (id: number) => {
    console.log("Read more clicked for post:", id);
  };

  const startIndex = (currentPage - 1) * postsPerPage;
  const endIndex = startIndex + postsPerPage;
  const currentPosts = posts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(posts.length / postsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        height: "100%",
      }}
    >
      {/* Blog Post List */}
      <Grid
        container
        spacing={4}
        direction="column"
        sx={{
          flex: 1, // Make the list grow to fill available height
          overflowY: "auto", // Allow scrolling if content exceeds height
          pb: 1, // Hack because the list clips the bottom shadow
        }}
      >
        {currentPosts.map((post) => (
          <Grid item key={post.id} xs={12}>
            <WootBlogPostCard
              title={post.title}
              excerpt={post.excerpt}
              image={post.image}
              onReadMore={() => handleReadMore(post.id)}
            />
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          sx={{ mx: 1 }}
        >
          Previous
        </Button>
        <Typography variant="body2" sx={{ alignSelf: "center", mx: 2 }}>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          sx={{ mx: 1 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default WootBlogPostList;
