import React from "react";
import "./SplashPage.css";
import WootButton from "../components/WootButton";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import WootLogo from "../components/WootLogo";

export default function SplashPage() {
  return (
    <Box
      className="App"
      sx={{
        textAlign: "center",
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          maxWidth: "100%",
          overflow: "hidden",
          width: "40vmin",
          height: "40vmin",
        }}
      >
        <WootLogo className="App-logo" />
      </Box>
      <Link to="/home">
        <WootButton wootStyles={{ variant: "secondary" }}>Enter</WootButton>
      </Link>
    </Box>
  );
}
