import React, { useEffect, useState } from "react";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAccount } from "../../contexts/AccountContext";
import AppBarUserInfoDropdown from "./AppBarUserInfoDropdown";
import WootSignInButton from "../WootSignInButton";
import WootButton from "../WootButton";

function AppBarRightContent() {
  const { account, loading, error, login, logout } = useAccount();
  const [open, setOpen] = useState(!!error); // Control dialog state

  // Automatic login on mount
  useEffect(() => {
    login();
  }, [login]);

  // Effect to watch for error changes and open/close the dialog
  useEffect(() => {
    if (error) {
      setOpen(true); // Open the dialog when error is present
    }
  }, [error]); // Run the effect when the error changes

  const handleLogoutClose = () => {
    setOpen(false);
    logout(); // Trigger logout when dialog is closed
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (account) {
    return <AppBarUserInfoDropdown account={account} />;
  } else if (loading) {
    return <CircularProgress size="1.5em" color="inherit" sx={{ mr: 2 }} />;
  } else {
    return (
      <>
        {error && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
          >
            <DialogTitle id="error-dialog-title">Oops!</DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Alert severity="error">
                We failed to retrieve your account!
              </Alert>
              <DialogContentText
                id="error-dialog-description"
                style={{ opacity: 0.75 }}
              >
                Error detail: {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <WootButton
                onClick={handleClose}
                wootStyles={{ variant: "destructive" }}
              >
                Ignore
              </WootButton>
              <WootButton
                onClick={handleLogoutClose}
                wootStyles={{ variant: "secondary" }}
                autoFocus
              >
                Logout
              </WootButton>
            </DialogActions>
          </Dialog>
        )}
        <WootSignInButton
          wootStyles={{ variant: error ? "destructive" : "primary" }}
        />
      </>
    );
  }
}

export default AppBarRightContent;
