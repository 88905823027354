import React, { useState } from "react";
import WootSigninForm from "./WootSigninForm";
import WootEmailVerificationForm from "./WootEmailVerificationForm";
import WootSignupForm from "./WootSignupForm";
import WootForgotPasswordForm from "./WootForgotPasswordForm";
import WootResetPasswordForm from "./WootResetPasswordForm";

export enum LoginMenu {
  Signin,
  Signup,
  VerifyEmail,
  ForgotPasswordForm,
  ResetPassword,
}

interface WootLoginProps {
  menuState?: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
  emailState?: [string, React.Dispatch<React.SetStateAction<string>>];
}

function WootLogin({
  menuState: providedMenuState,
  emailState: providedEmailState,
}: WootLoginProps) {
  const defaultMenuState = useState<LoginMenu>(LoginMenu.Signin);
  const defaultEmailState = useState<string>("");

  const menuState = providedMenuState || defaultMenuState;
  const emailState = providedEmailState || defaultEmailState;

  // Destructure states
  const [menu] = menuState;

  // Render components based on the loginMenu state
  switch (menu) {
    case LoginMenu.VerifyEmail:
      return (
        <WootEmailVerificationForm
          menuState={menuState}
          emailState={emailState}
        />
      );
    case LoginMenu.ForgotPasswordForm:
      return (
        <WootForgotPasswordForm menuState={menuState} emailState={emailState} />
      );
    case LoginMenu.ResetPassword:
      return (
        <WootResetPasswordForm menuState={menuState} emailState={emailState} />
      );
    case LoginMenu.Signup:
      return <WootSignupForm menuState={menuState} emailState={emailState} />;
    case LoginMenu.Signin:
    default:
      return <WootSigninForm menuState={menuState} emailState={emailState} />;
  }
}

export default WootLogin;
