import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { appConfig } from "../config";

// Define the AuthContext type
interface AuthContextType {
  getAccessToken: () => Promise<string | null>; // Expose a promise to get the token
  redirectAuth: () => void; // Redirect the user to login if not authenticated
  setAccessToken: (token: string | null) => void; // Allow setting the access token
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Helper function to get cookie value
const getCookieValue = (name: string): string | null => {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

// Function to parse and check if JWT token is expired
const isTokenExpired = (token: string | null): boolean => {
  if (!token) return true;

  const [, payload] = token.split("."); // Extract payload from JWT token
  if (!payload) return true;

  const decoded = JSON.parse(atob(payload));
  const currentTime = Math.floor(Date.now() / 1000);
  return decoded.exp < currentTime; // Check expiration time
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getCookieValue("auth_access_token")
  ); // Initialize state with cookie value
  const navigate = useNavigate();
  const location = useLocation();

  // Function to refresh access token
  const refreshAccessToken = async (): Promise<string | null> => {
    try {
      const response = await fetch(`${appConfig.warden_url}/refresh`, {
        method: "POST",
        headers: { accept: "application/json" },
        credentials: "include", // Ensures cookies are sent and received
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access_token); // Update state with new access token
        return data.access_token;
      } else {
        console.warn("Failed to refresh access token");
        setAccessToken(null); // Clear access token if refresh failed
        return null;
      }
    } catch (error) {
      console.error("Error during token refresh:", error);
      setAccessToken(null); // Clear token if there's an error
      return null;
    }
  };

  // Function to check the token and refresh if necessary
  const getAccessToken = async (): Promise<string | null> => {
    // Use the access token from state if it exists and is not expired
    if (accessToken && !isTokenExpired(accessToken)) {
      return accessToken;
    }
    // Token is expired or missing, attempt to refresh
    const refreshedToken = await refreshAccessToken();
    return refreshedToken;
  };

  // Method to check the existence of the cookie and redirect if necessary
  const redirectAuth = () => {
    const isLoginPage = location.pathname === "/login";

    if (!accessToken && !isLoginPage) {
      const redirectUrl = encodeURIComponent(
        location.pathname + location.search + location.hash
      );
      navigate(`/login?redirect=${redirectUrl}`);
    }
  };

  return (
    <AuthContext.Provider
      value={{ getAccessToken, setAccessToken, redirectAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
