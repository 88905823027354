import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import WootLogo from "../components/WootLogo";
import { useAccount } from "../contexts/AccountContext";

function LogoutPage() {
  const { logout } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();

  // Logout if account is active
  useEffect(() => {
    const checkLogout = async () => {
      await logout();

      // Read redirect URL from query parameters
      const redirectUrl = new URLSearchParams(location.search).get("redirect");

      if (redirectUrl) {
        const decodedRedirectUrl = decodeURIComponent(redirectUrl);
        // Check if the URL is an external link (starts with http or https)
        if (
          decodedRedirectUrl.startsWith("http://") ||
          decodedRedirectUrl.startsWith("https://")
        ) {
          window.location.href = decodedRedirectUrl; // Redirect to the external URL
        } else {
          navigate(decodedRedirectUrl); // Redirect to local route if it's internal
        }
      } else {
        navigate("/home"); // Redirect to home if no redirect URL
      }
    };
    checkLogout();
  }, [location.search, logout, navigate]);

  return (
    <Container
      sx={{
        padding: "20px",
        textAlign: "center",
        marginTop: "50px",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "200px",
          margin: "0 auto",
        }}
      >
        <WootLogo />
      </Box>
      <CircularProgress
        size={100}
        color="secondary"
        sx={{ marginTop: "20px" }}
      />
      <Typography variant="h6" sx={{ marginTop: "20px" }}>
        Signing out...
      </Typography>
    </Container>
  );
}

export default LogoutPage;
