import React from "react";
import { Container } from "@mui/material";
import ResponsiveAppBar from "../components/AppBar/ResponsiveAppBar";
import Footer from "../components/Footer";
import { Outlet, useLocation } from "react-router-dom";
import GameCanvas from "../components/GameCanvas";

export default function GameAppPage() {
  const location = useLocation();

  // Check if the current route is "/play"
  const isPlayRoute = location.pathname === "/play";

  return (
    <Container
      style={{
        padding: "0",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ResponsiveAppBar />
      <Outlet />
      {/*
        We have the canvas always mounted, but it's only visible if we are
        on the /play route.
      */}
      <GameCanvas visible={isPlayRoute} />
      <Footer />
    </Container>
  );
}
