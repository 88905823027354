interface AppConfig {
  base_url: string;
  client_url: string;
  warden_url: string;
  aura_url: string;
  discordLinkRedirect: string;
  stripe_public_key: string;
}

let config: AppConfig;
const environment = process.env.REACT_APP_ENV;
const STRIPE_LIVE_PUBLIC_KEY =
  "pk_live_51QAJPWDISBB3cqfmc67jiyM0RDilz6y00p24KdoJP2azZpCOGgQMNO54waxVmoOJ9SOSI7xEuZ08FOa1DG8vrR0000g3JCWkzm";
const STRIPE_TEST_PUBLIC_KEY =
  "pk_test_51QAJPWDISBB3cqfmaUVpEML0CGc8jk7FYKABjcmWJ00xLAbT3l0YgLhJQZgqtHEzsyhDqADatAjepUyOXOYF3YVR00xfWFbzo0";
if (environment === "prod") {
  config = {
    base_url: "https://www.wootnewts.com",
    client_url: "https://client.wootnewts.com",
    warden_url: "https://warden.wootnewts.com",
    aura_url: "https://aura.loopystudios.com",
    discordLinkRedirect:
      "https://discord.com/oauth2/authorize?response_type=token&client_id=1232057687869493372&scope=identify&redirect_uri=https%3A%2F%2Fwww.wootnewts.com%2Flink-discord",
    stripe_public_key: STRIPE_LIVE_PUBLIC_KEY,
  };
} else if (environment === "stg") {
  config = {
    base_url: "https://stg.wootnewts.com",
    client_url: "https://client-stg.wootnewts.com",
    warden_url: "https://warden-stg.wootnewts.com",
    aura_url: "https://aura-stg.loopystudios.com",
    discordLinkRedirect:
      "https://discord.com/oauth2/authorize?response_type=token&client_id=1232057687869493372&scope=identify&redirect_uri=https%3A%2F%2Fstg.wootnewts.com%2Flink-discord",
    stripe_public_key: STRIPE_TEST_PUBLIC_KEY,
  };
} else if (environment === "dev") {
  config = {
    base_url: "https://dev.wootnewts.com",
    client_url: "https://client-dev.wootnewts.com",
    warden_url: "https://warden-dev.wootnewts.com",
    aura_url: "https://aura-dev.loopystudios.com",
    discordLinkRedirect:
      "https://discord.com/oauth2/authorize?response_type=token&client_id=1232057687869493372&scope=identify&redirect_uri=https%3A%2F%2Fdev.wootnewts.com%2Flink-discord",
    stripe_public_key: STRIPE_TEST_PUBLIC_KEY,
  };
} else {
  // Local
  config = {
    base_url: "https://localhost.loopystudios.com:3000",
    client_url: "https://client-dev.wootnewts.com",
    warden_url: "https://warden-dev.loopystudios.com",
    aura_url: "https://aura-dev.loopystudios.com",
    discordLinkRedirect:
      "https://discord.com/oauth2/authorize?response_type=token&client_id=1232057687869493372&scope=identify&redirect_uri=https%3A%2F%2Flocalhost.loopystudios.com%3A3000%2Flink-discord",
    stripe_public_key: STRIPE_TEST_PUBLIC_KEY,
  };
}

export const appConfig = config;
