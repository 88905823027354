import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import mobileCheck from "../utils/mobileCheck";
import AppleIcon from "@mui/icons-material/Apple"; // Import Apple icon
import AndroidIcon from "@mui/icons-material/Android"; // Import Android icon
import WootButton from "./WootButton";
import { appConfig } from "../config";

interface GameCanvasProps {
  visible: boolean;
}

export default function GameCanvas({ visible }: GameCanvasProps) {
  const [isMobileDevice, setIsMobileDevice] = useState(mobileCheck());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(mobileCheck());
    };

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobileDevice ? (
    <Paper
      sx={{
        display: visible ? "flex" : "none",
        p: 4,
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "common.black",
        borderRadius: { xs: 0, lg: "20px" },
      }}
      square
    >
      <Typography
        variant="h5"
        sx={{ textAlign: "center", color: "common.black", mb: 2 }}
      >
        Woot Newts is not stable on mobile web browsers.
        <br />
        Instead, please download the mobile app.
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <WootButton
          variant="contained"
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#007AFF",
          }}
          disabled
        >
          <AppleIcon sx={{ mr: 1 }} />
          App Store
        </WootButton>
        <WootButton
          variant="contained"
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#3DDC84",
          }}
          disabled
        >
          <AndroidIcon sx={{ mr: 1 }} />
          Google Play
        </WootButton>
      </Box>
    </Paper>
  ) : (
    <Box
      sx={{
        display: visible ? "flex" : "none",
        flexDirection: "column",
        flex: "1", // Allow iframe to expand
        width: "100%",
        height: "100%",
        minHeight: "calc(540px + 90px)", // +90 for ad height
        borderRadius: { xs: 0, lg: "20px" },
      }}
    >
      <iframe
        allow="fullscreen"
        src={appConfig.client_url}
        title="Woot Newts Client"
        style={{
          flex: "1",
          width: "100%",
          height: "100%",
          border: "none", // Remove border
        }}
        loading="lazy" // Improve performance by lazy loading the iframe
      />
    </Box>
  );
}
